import React from "react";

const Education = () => {
  return (
    <section className="light" id="portfolio">
      <h2>Education</h2>
    </section>
  );
};

export default Education;
